<template>
  <!-- <div> <iframe :src="PDFData" frameborder="0" style="width: 100%; height: 100vh"></iframe></div> -->
  <div class='ukeyGuide'>
    <pdfPreview :url='PDFData'></pdfPreview>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      PDFData:
        'https://yhca-pro-public-download.oss-cn-hangzhou.aliyuncs.com/seal_assistant_operating_instruction.pdf'
    }
  },
  created() {}
}
</script>

<style>
body,
html {
  min-width: 100px;
  width: 100%;
}
.ukeyGuide {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
